import axios from "axios";
import { dataURLtoFile, localImageEquals } from "./image.helpers";
import localForage from "localforage";
import {generateGcodePayload} from "./print.job";

export const API_URL = process.env.REACT_APP_API_URL;

export async function fetchTemplates(page = 1, sortBy = '', sortOrder = '', canvasId = -1, pageSize = 6) {
    //TODO: pagination
    const queryParams = new URLSearchParams();
    queryParams.append("populate", "*");
    queryParams.append("sort", `${sortBy || 'order'}:${sortOrder || 'ASC'}`);
    if (canvasId.toString().startsWith('g_')) {
        queryParams.append("filters[groups][id][$eq]", canvasId.toString().split('_')[1]);
    } else if (canvasId.toString().startsWith('ng_')) {
        queryParams.append("filters[groups][id][$ne]", canvasId.toString().split('_')[1]);
    }
    else if (canvasId > 0) {
        queryParams.append("filters[canvas][id][$eq]", canvasId);
    }
    queryParams.append("pagination[page]", page);
    queryParams.append("pagination[pageSize]", pageSize);

    return await axios.get(`${API_URL}/api/templates?${queryParams.toString()}`);
}

export async function fetchFonts(page = 1, accountId = '') {
    //TODO: pagination
    return await axios.get(`${API_URL}/api/fonts?fields[0]=name&fields[1]=slug&sort=name:ASC&pagination[pageSize]=100&filters[$or][0][accountId][$null]=true${accountId ? `&filters[$or][1][accountId][$eq]=${accountId}` : ''}`);
}

export async function fetchFont(fontId) {
    if (!fontId) return undefined;
    return await axios.get(`${API_URL}/api/fonts/${fontId}`);
}

export async function fetchInkLoomFonts() {

    return await axios.get(`${API_URL}/api/ink-loom-fonts/`);
}

export async function fetchInkLoomFont(fontId)
{
    if (!fontId) return undefined;
    return await axios.get(`${API_URL}/api/ink-loom-fonts/${fontId}`);
}

export async function fetchGroups(page = 1, featured = false) {
    return await axios.get(`${API_URL}/api/groups?populate=*&sort=name:ASC&pagination[pageSize]=100${featured ? '&filters[featured][$eq]=true' : ''}`);
}

export async function fetchGroup(groupId) {
    if (!groupId) return undefined;
    return await axios.get(`${API_URL}/api/groups/${groupId}?populate=*`);
}

export async function fetchCanvases(page = 1, featured = false) {
    return await axios.get(`${API_URL}/api/canvases?populate=*&sort=name:ASC&pagination[pageSize]=100&filters[widthInMillimeters][$ne]=0${featured ? '&filters[featured][$eq]=true' : ''}`);
}

export async function fetchCanvas(canvasId) {
    if (!canvasId) return undefined;
    return await axios.get(`${API_URL}/api/canvases/${canvasId}?populate=*`);
}

export async function fetchTemplate(templateId) {
    return await axios.get(`${API_URL}/api/templates/${templateId}?populate=*`);
}

export async function fetchTextContent(templateId) {
    return await axios.get(`${API_URL}/api/text-contents?populate=*&sort=key:ASC&pagination[pageSize]=100`);
}
export function getTextContent(base, key) {
    const res = base?.data?.data?.find((entry) => entry.attributes.key === key);
    return res ? { content: res.attributes.content, media: getMediaUrl(res.attributes.media), mediaType: (getMediaUrl(res.attributes.media) ? res.attributes.media.data.attributes.mime : '') } : { content: '', media: '', mediaType: '' }
}

export function getMediaUrl(mediaObj, format = 'large') {
    const full = mediaObj?.data?.attributes?.url?.startsWith('http') ? mediaObj.data.attributes.url : (mediaObj?.data?.attributes?.url ? `${API_URL}${mediaObj.data.attributes.url}` : '');
    if (!format) return full;
    const detail = mediaObj?.data?.attributes?.formats?.[format]?.url?.startsWith('http') ? mediaObj.data.attributes.formats[format].url : (mediaObj?.data?.attributes?.formats?.[format]?.url ? `${API_URL}${mediaObj.data.attributes.formats[format].url}` : '');
    if (!detail) return full;
    return detail;
}

export const ErrorLevels = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
}

export async function serverLog(level, message, additionalData = null) {
    return await axios.post(`${API_URL}/api/logs`, {
        data: {
            eventLevel: level,
            message,
            eventDate: new Date(),
            data: additionalData
        }
    });
}

export async function sendContactEmail(data) {
    return await axios.post(`${API_URL}/swell/contact`, data);
}

export async function uploadUserContent(data) {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    }
    return await axios.post(`${API_URL}/swell/upload`, data, config);
}

export const uploadCustomization = async (customization = {}) => {
    //upload bg to cloud and store links in orderData
    const logoData = await localForage.getItem('logo');
    const customBgData = await localForage.getItem('customBg');
    const customInnerBgData = await localForage.getItem('customInnerBg');
    const previewInnerBgData = await localForage.getItem('previewInnerBg');
    const previewEnvelopeBgData = await localForage.getItem('previewEnvelopeBg');

    const ret = { logo: '', customBg: '', customInnerBg: '', previewInnerBg: '', previewEnvelopeBg: '' };

    // TODO: lets consider removing this redundancy

    if (customBgData) {
        if (!await localImageEquals(customBgData.data, customization?.customBg || '')) {
            const bgFile = dataURLtoFile(customBgData.data, 'background.jpg');
            const data = new FormData();
            data.append('files', bgFile, bgFile.name);

            const response = await uploadUserContent(data);
            ret.customBg = response.data.data.url;
        } else {
            ret.customBg = customization?.customBg || '';
        }
    }

    if (customInnerBgData) {
        if (!await localImageEquals(customInnerBgData.data, customization?.customInnerBg || '')) {
            const bgFile = dataURLtoFile(customInnerBgData.data, 'background.jpg');
            const data = new FormData();
            data.append('files', bgFile, bgFile.name);

            const response = await uploadUserContent(data);
            ret.customInnerBg = response.data.data.url;
        } else {
            ret.customInnerBg = customization?.customInnerBg || '';
        }
    }

    if (logoData) {
        if (!await localImageEquals(logoData.data, customization?.logo || '')) {
            const bgFile = dataURLtoFile(logoData.data, 'logo.png');
            const data = new FormData();
            data.append('files', bgFile, bgFile.name);

            const response = await uploadUserContent(data);
            ret.logo = response.data.data.url;
        } else {
            ret.logo = customization?.logo || '';
        }
    }

    if (previewInnerBgData) {
        if (!(await localImageEquals(previewInnerBgData.data, customization?.previewInnerBg || ''))) {
            const bgFile = dataURLtoFile(previewInnerBgData.data, 'previewInnerBg.jpg');
            const data = new FormData();
            data.append('files', bgFile, bgFile.name);

            const response = await uploadUserContent(data);
            ret.previewInnerBg = response.data.data.url;
        } else {
            ret.previewInnerBg = customization?.previewInnerBg || '';
        }
    }

    if(previewEnvelopeBgData) {
        if (!(await localImageEquals(previewEnvelopeBgData.data, customization?.previewEnvelopeBg || ''))) {
            const bgFile = dataURLtoFile(previewEnvelopeBgData.data, 'previewEnvelopeBg.jpg');
            const data = new FormData();
            data.append('files', bgFile, bgFile.name);

            const response = await uploadUserContent(data);
            ret.previewEnvelopeBg = response.data.data.url;
        } else {
            ret.previewEnvelopeBg = customization?.previewEnvelopeBg || '';
        }
    }

    return ret;
};

export async function saveDraft(id, orderData) {
    return await axios.post(`${API_URL}/swell/drafts`, { id, orderData: JSON.stringify({ ...orderData, customization: await uploadCustomization(orderData.customization) }) });
}

export async function deleteDraft(id, uuid) {
    return await axios.delete(`${API_URL}/swell/drafts/${id}/${uuid}`);
}

export async function fetchWunderlink(link) {
    return await axios.get(`${API_URL}/swell/wunderlink/${link}`);
}

export async function fetchGCode(collectedFields) {
    const payload = generateGcodePayload(collectedFields);
    if (!payload) return undefined;
    return await axios.post(`${API_URL}/api/gcode`, payload);
}

export async function sendPrintJob(payload) {
    const response = await axios.post(`${API_URL}/api/gcode-job`, payload);
    return response.data;
}

export async function getFileContent(key) {
    const response = await axios.get(`${API_URL}/swell/download/${key}`, {
        responseType: 'arraybuffer'
    });
    
    const contentType = response.headers['content-type'] || 'image/jpeg';
    const base64 = btoa(
        new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    
    return `data:${contentType};base64,${base64}`;
}