import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ErrorLevels, serverLog } from './helpers/api';

let missingTexts = [];

i18n.use(initReactI18next).init({
  lng: 'de',
  // we init with resources
  resources: {
    de: {
      translations: {
        //Templates
        'What would you like to send today?': 'Was möchtest Du versenden?',
        'No templates found': 'Keine Vorlagen gefunden',
        //Profile
        'My account': 'Mein Konto',
        Account: 'Konto',
        'Order history': 'Bestellverlauf',
        Addresses: 'Adressen',
        //Recover
        'You can also:': 'Du kannst auch:',
        'Go to login page': 'Gehe zur Login Page',
        or: 'oder',
        'Create an account': 'Erstelle Dir einen Benutzeraccount',
        'Forgot your password?': 'Passwort vergessen?',
        'E-mail': 'E-Mail',
        'New password': 'Neues Passwort',
        //SignIn
        'First time here?': 'Zum ersten Mal hier?',
        'Join us and surprise somebody with a handwritten card!':
          'Registriere Dich und überrasche jemanden mit einer handschriftlichen Karte!',
        'You will need an account to send it.':
          'Du brauchst einen Benutzeraccount zum Versenden.',
        'Welcome back!': 'Willkommen zurück!',
        Password: 'Passwort',
        'Forgot password?': 'Passwort vergessen?',
        'Sign In': 'Login',
        //SignUp
        'Have an account?': 'Besitzt Du bereits einen Account?',
        'Log in and let’s send somebody a handwritten card!':
          'Log Dich ein und überrasche jemanden mit einer handschriftlichen Karte!',
        'Nice to meet you!': 'Schön, Dich zu sehen!',
        'First name': 'Vorname',
        'Last name': 'Nachname',
        'Sign up': 'Registrieren',
        //Account
        Name: 'Name',
        'Name changed successfully': 'Name erfolgreich geändert',
        'Save changes': 'Änderungen speichern',
        'Change password': 'Passwort ändern',
        'Confirm new password': 'Bestätige neues Passwort',
        'Password changed successfully':
          'Das Passwort ist dann erfolgreich geändert',
        //Addresses
        'Delivery address': 'Lieferadresse',
        'Address changed successfully': 'Adresse erfolgreich geändert',
        'Address line 1': 'Adresszeile 1',
        'Address line 2': 'Adresszeile 2',
        'Address line 2 *': 'Adresszeile 2 *',
        'Address line 2 (optional)': 'Adresszeite 2 (optional)',
        'Postal code': 'Postleitzahl',
        City: 'Stadt',
        Country: 'Land',
        'Billing address': 'Rechnungsadresse',
        'Billing changed successfully': 'Rechnungsadresse erfolgreich geändert',
        Phone: 'Telefonnummer',
        //Orders
        'Order ID': 'Auftragsnummer',
        Date: 'Datum',
        Quantity: 'Menge',
        Template: 'Vorlage',
        Status: 'Status',
        Value: 'Wert',
        Files: 'Dateien',
        None: 'Keiner',
        Receivers: 'Empfänger',
        Page: 'Seite',
        of: 'von',
        'Previous page': 'Vorherige Seite',
        'Next page': 'Nächste Seite',
        //CardOrder
        Back: 'Zurück',
        Next: 'Weiter',
        Content: 'Beschriftung',
        Payment: 'Zahlung',
        Order: 'Bestellung',
        Data: 'Daten',
        //TypeSelect
        'Which salutations appear in your mailing? (Please select)': 'Wie möchtest Du die Empfänger ansprechen?',
        //Contents
        'Text style': 'Schrift Stil',
        'contents_step_headings': 'Gewünschte Anrede / Name Kombination auswählen',
        'contents_step_headings_hint': 'Anrede & Adresse werden als „Maxi Muster“ in der Vorschau angezeigt',
        'contents_step_text_input': 'Text eingeben',
        'contents_step_text_input_hint': 'Hier Text eingeben...',
        'contents_step_font_choice': 'Schrift auswählen',
        'contents_optional_spacer': 'Optional',
        'contents_data_hint': 'Adressupload auf den nachfolgenden Seiten',
        'contents_addresses': 'Adresse eingeben',
        'contents_warning1': 'Achtung:',
        'contents_warning2': 'Bitte beachte, dass Zeilenumbrüche sowie einzelne Wörter und Buchstaben durch die in unseren Handschriften hinterlegten Varianzen vom Freigabemuster abweichen können.',
        'contents_warning3': 'So wird jede Karte und jeder Brief zum Unikat.',
        'small': 'Klein',
        'medium': 'Mittel',
        'large': 'Groß',
        Font: 'Schriftart',
        'Text size': 'Schriftgröße',
        Text: 'Text',
        'Heading on the left side': 'Anrede links',
        'Heading on the right side': 'Anrede rechts',
        Body: 'Inhalt',
        'Body left': 'Text links',
        'Body right': 'Text rechts',
        Address: 'Adresse',
        'Recipient name': 'Empfänger Name',
        'Alternative text': 'Alternativtext (bspw. "Du/Sie" Variante)',
        "Card thickness": "Kartenstärke",
        "Envelope thickness": "Umschlagstärke",
        //Data
        'Download data template': 'Daten-Template herunterladen',
        'Download template': 'Template herunterladen',
        'Import receivers’ data': 'Empfängerdaten importieren',
        'Choose': 'Wählen',
        File: 'Datei',
        'is selected': 'wurde ausgewählt',
        'Please select or drop a file to import':
          'Datei auswählen oder per drag and drop hier her ziehen',
        //Finalize
        'Wunderpen account': 'Wunderpen Benutzerkonto',
        'You need to sign into a Wunderpen account to order cards.': 'Sie müssen sich registrieren um eine Bestellung abgeben zu können.',
        'Sign in': 'Anmelden',
        'Sign out': 'Abmelden',
        //Order
        'Choose your order': 'Bitte wähle aus',
        'Choose your order_description': 'Jedes Schriftstück kann mit variablen Textbausteinen individualisiert werden. Welche Varianzen möchtest Du definieren? / nutzen / hinzufügen?',
        'Number of cards': 'Stückzahl',
        Amount: 'Menge',
        'Cost per card': 'Kosten je Stück',
        over: 'Mehr als',
        'contact us': 'bitte kontaktiere uns',
        //Payment
        'Provide billing information': 'Zahlungsinformationen eingeben',
        'Payment method': 'Zahlmethode',
        'Payment method will be chosen on checkout':
          'Die Zahlmethode wird beim Checkout gewählt',
        //Shipping
        'Your cards will be sent out to their intended recipients.':
          'Die Karten werden direkt zu den Empfängern gesendet.',
        'Your cards will be sent out to:': 'Die Karten werden direkt zu:',
        'What should we do with your cards?':
          'Wohin sollen wir Deine Schriftstücke versenden?',
        'Send to me': 'Zu mir senden',
        'Send to receivers': 'An Empfänger versenden',
        'Choose a shipping date': 'Versanddatum wählen',
        'Select date': 'Datum auswählen',
        'Envelope closed': 'Umschläge schließen',
        'Envelope open': 'Umschläge offen lasse',
        //Summary
        Summary: 'Zusammenfassung',
        pieces: 'Stück',
        Shipping: 'Versand',
        Total: 'Gesamt',
        Checkout: 'Checkout',
        //TemplateRenderer
        Inside: 'Vorderseite',
        Outside: 'Rückseite',
        Envelope: 'Umschlag',
        'Download preview': 'Vorschau herunterladen',
        //Heading
        Heading: 'Anrede',
        //Dashboard
        Format: 'Format',
        'Delete templates': 'Vorlagen löschen',
        'Shop dashboard': 'Swell Dashboard',
        'Create template': 'Vorlage erstellen',
        'Admin dashboard': 'Admin Dashboard',
        'Admin key': 'Admin-Passwort',
        //TemplateModal
        Overview: 'Übersicht',
        Price: 'Preis',
        '€ / Card': '€ / Stück',
        Material: 'Material',
        Description: 'Beschreibung',
        'Send one card': 'Eine Karte versenden',
        'Send multiple cards': 'Mehrere Karten versenden',
        //Cookies
        'This website uses cookies to enhance the user experience.':
          'Ihre Zufriedenheit ist unser Ziel, deshalb verwenden wir Cookies. Mit diesen ermöglichen wir, dass unsere Webseite zuverlässig und sicher läuft, wir die Performance im Blick behalten und Sie besser ansprechen können. Cookies werden benötigt, damit technisch alles funktioniert und Sie auch externe Inhalte lesen können. Weiter Informationen finden Sie in unsere Datenschutzerklärung.',
        'I understand': 'Ich stimme der Verwendung von Cookies zu',
        'Accept all cookies': 'Alle Cookies akzeptieren',
        'Accept only necessary cookies': 'Alle cookies ablehnen',
        //Form errors
        Required: 'Erforderlich',
        'Invalid email': 'Ungültige E-Mail',
        'Password is too short - should be 6 chars minimum.':
          'Das Passwort ist zu kurz - sollte mindestens 6 Zeichen lang sein.',
        'Password reset key has expired.':
          'Der Schlüssel zum Zurücksetzen des Kennworts ist abgelaufen.',
        'Invalid email or password': 'Ungültige E-Mail oder Passwort',
        'Too Short!': 'Zu kurz',
        'Too Long!': 'Zu lang',
        'Failed to register. Please contact an administrator.':
          'Registrierung fehlschlagen. Bitte wenden Sie sich an einen Administrator.',
        'Passwords must match': 'Passwörter müssen übereinstimmen',
        'There was an error when changing the name.':
          'Beim Ändern des Namens ist ein Fehler aufgetreten.',
        'There was an error when changing the address.':
          'Beim Ändern der Adresse ist ein Fehler aufgetreten.',
        'There was an error when changing the billing address.':
          'Beim Ändern der Rechnungsadresse ist ein Fehler aufgetreten.',
        'Invalid admin key': 'Ungültiges Admin-Passwort',
        //Order Statuses
        'order_pending': 'Offen',
        'order_draft': 'Entwurf',
        'order_payment_pending': 'Zahlung offen',
        'order_delivery_pending': 'Lieferung ausstehend',
        'order_hold': 'Halt',
        'order_complete': 'Komplett',
        'order_canceled': 'Storniert',
        //Splashscreen
        'Please wait': 'Bitte warten',
        //Mobile
        'Mobile version is not ready yet. Please use a computer to visit Wunderpen shop.': 'Hi, leider ist unser Wunderpen Online Shop noch nicht auf mobilen Endgeräten erreichbar. Wir arbeiten aber unter Hochdruck daran, auch dies für Dich möglich zu machen.',

        //Stage 2 translations
        'Send cards': 'Los gehts',
        'Add new card': 'Hinzufügen',
        'Add new variable': 'Variable hinzufügen',
        'AI': 'AI',
        'Card': 'Schriftstück',
        'Choose recipients data input method type': 'Wie möchtest Du die Empfänger-Daten an uns übertragen?',
        'Confirm': 'Bestätigen',
        'Data Template Upload': 'Vorlage zur Dateneingabe',
        'Data type selection': 'Auswahl Dateneingabe',
        'feminine': 'weiblich',
        'masculine': 'männlich',
        'File upload': 'Datei hochladen',
        'Gender': 'Geschlecht',
        'Manual': 'Manuelle Eingabe',
        'Next card': 'Nächstes',
        'none': 'wähle',
        'personalized': 'Personalisierte Anrede & Adresse',
        'personalized_with_variables': 'Personalisierte Anrede & Adresse \n+ individuelle Textbausteine',
        'invitation': 'Einladung \n(demnächst)',
        'full_custom': 'Vollständige Individualisierung \n(jedes stück freier text)',
        'Please enter the variable name (without spaces)': 'Bennene die Variable (keine leerzeichen)',
        'Previous card': 'Vorheriges',
        'Remove card': 'Löschen',
        'Send cards': 'Los gehts',
        'Text Template': 'Textvorlage',
        'Variable name': 'Name',
        'Variables': 'Variable Bausteine',
        'Variant': 'Textvorlage',
        'Variants': 'Textvorlagen',
        'Your cards will be sent out to their intended recipients': 'Deine Schriftstücke werden einzeln zu den jeweiligen Empfänger-Adressen versendet.',
        'This field contains errors': 'Fehlerhafte Eingabe',
        'version': 'Version',
        'Template (du version)': 'Textvorlage "Du-Version"',
        'Template (ihr version)': 'Textvorlage "Ihr-Version"',
        'Template (sie version)': 'Textvorlage "Sie-Version"',
        'Variable Name': 'Namen eingeben',
        'Template body': 'Nächste Textvorlage',
        'You are now editing the': 'Bitte erstelle nun die Textvorlage der',
        'version template.': '-Version.',
        'Would you like to copy the template body?': 'Möchtest Du hierzu den vorherigen Text als Vorlage übernehmen und weiter bearbeiten?',
        'Copy from': 'Ja, Text von\n{{variant}}\nübertragen',
        'Do not copy': 'Nein, neu starten',
        'btn_du': 'per "Du"',
        'btn_sie': 'per "Sie"',
        'btn_ihr': 'per "Ihr"',
        'du': 'Du',
        'sie': 'Sie',
        'ihr': 'Ihr',
        'Please fill text template': 'Textvorlage der "{{variant}}-Version"',
        'Variable': 'Variable',
        'No.': 'Nr.',
        //28.05.2022 translations
        'Edit card': 'Bearbeiten',
        'Recipient data input': 'Eingabe der Empfängerdaten',
        'In the next stage you can provide the recipient data for each card. If you would like to import Excel spreadsheet files with recipient data, please use the desktop version of the app.': 'Im nächsten Schritt kannst Du für jede Karte die Empfängerdaten hinterlegen. Wenn Du Excel-Tabellendateien mit Deinen Empfängerdaten importieren möchtest, verwende bitte die Desktop-Version der App.',
        'Dashboard': 'Dashboard',
        'Orders': 'Bestellungen',
        'Strapi Admin Panel': 'Strapi Administrationsmenü',
        'Swell Admin Panel': 'Swell Administrationsmenü',
        'Dashboard login': 'Dashboard-Anmeldung',
        'Back to dashboard': 'Zurück zum Dashboard',
        'Billing Name': 'Abrechnungsname',
        'Path': 'Weg',
        'Canvas': 'Leinwand',
        'Copied to clipboard': 'In die Zwischenablage kopiert',
        'Copy card content to clipboard': 'Kopiere den Karteninhalt in die Zwischenablage',
        'Copy address to clipboard': 'Adresse in die Zwischenablage kopieren',
        'Copy All': 'Alles kopieren',
        'Back to orders': 'Zurück zu Bestellungen',
        'Order detail': 'Bestelldetails',
        'Export Excel': 'Excel exportieren',
        'Open in Swell': 'Öffnen in Swell',
        'Order data': 'Bestelldaten',
        'Shipping date': 'Versanddatum',
        'Order complete': 'Bestellung ausgeführt',
        'Your order has been placed and will be realized soon.': 'Deine Bestellung wurde aufgegeben und wird in Kürze bearbeitet.',
        'Back to homepage': 'Zurück zur Startseite',
        'Are you sure you want to remove the card?': 'Möchtest Du das Schriftstück wirklich entfernen?',
        'Cancel': 'Abbrechen',
        'Finish editing': 'Fertig',
        'Send cards to their intended recipients': 'Senden Sie Karten an die vorgesehenen Empfänger',
        //CC
        'Credit card': 'Kreditkarte',
        'Credit card number': 'Kreditkartennummer',
        'Exp. month': 'Ablaufmonat',
        'Exp. year': 'Ablaufjahr',
        'Exp. date': 'Kartenablauf',
        'CVC': 'Prüfnummer',
        'Brand': 'Kreditkartenmarke',
        'Use saved credit card': 'Gespeicherte Kreditkarte verwenden',
        'Finish': 'Fertig',
        'Credit card holder': 'Kreditkartenbesitzer',
        'The card details you provided are incorrect.': 'Die von Ihnen angegebenen Kartendaten sind falsch.',
        'OK': 'OK',
        'Error: Card was declined': 'Kreditkarte wurde abgelehnt',
        'There was an error while processing your payment': 'Bei der Verarbeitung Ihrer Zahlung ist ein Fehler aufgetreten',
        'Provide billing address': 'Rechnungsadresse angeben',
        'Use shipping address': 'Lieferadresse verwenden',
        'We are unable to authenticate your payment method. Please choose a different payment method and try again.': 'Wir können Ihre Zahlung nicht authentifizieren. Bitte verwenden Sie eine andere Kreditkarte und versuchen Sie es erneut.',
        'Experts in handwritten mailings': 'Expert:innen für handschriftliche Mailings',
        'main_page_text': 'Jeder freut sich über Handschriftliche Mailings. Nutze unsere Technologie um mit wertschätzenden Botschaften Emotionen und Aufmerksamkeit zu wecken und Deine Liebsten stärker an Dich zu binden.',
        'contact_url': 'https://www.wunderpen.com/kontakt-aufnehmen',
        'Ship out': 'Versenden',
        'Coupon code (optional)': 'Gutscheincode (Optional)',
        'Coupon code': 'Gutscheincode',
        'Apply': 'Anwenden',
        'Remove': 'Abbrechen',
        'Discount': 'Rabatt',
        'contact_form_header': "Brauchst du unsere Hilfe?",
        'contact_form_text': 'Hast du Fragen rund um den Bestellprozess oder Vorschläge, wie wir unseren Webshop verbessern können? Füll einfach das Formular aus und unser freundliches Team wird sich umgehend mit dir in Verbindung setzen.',
        'contact_body': 'Deine Nachricht',
        'contact_name': 'Vollständiger Name',
        'contact_company': 'Firma',
        'contact_address': 'E-Mail Adresse',
        'contact_phone': 'Telefon (für Rückfragen)',
        'contact_send': 'Senden',
        'Could not submit the contact form': 'Das Kontaktformular konnte nicht gesendet werden',
        'Your inquiry has been sent successfully': 'Ihre Anfrage wurde erfolgreich versendet',
        'Page not found': 'Seite nicht gefunden',
        'Tax': 'MwSt. 19%',
        'Filter': 'Filter',
        'Custom': 'Eigenes Design',
        'Sort By': 'Sortieren nach',
        'sort_default': 'Standard',
        'sort_name': 'Name',
        'sort_canvas': 'Format',
        'sort_newest': 'Neueste',
        'sort_oldest': 'Älteste',
        'Visual Customization': 'Visuelle Anpassung',
        'Card visual customization': 'Visuelle Anpassung der Karte',
        'Add custom logo': 'Logo hinzufügen',
        'Add custom background': 'Hintergrund hinzufügen',
        'Error': 'Fehler',
        'Finish cropping': 'Zuschneiden beenden',
        'Crop image': 'Bild zuschneiden',
        'maximum size:': 'maximale Größe:',
        'PNG or JPG': 'PNG oder JPG',
        'Background': 'Hintergrund',
        'Logo': 'Logo',
        'image_crop_text': 'Schneide und passe das Bild an deine Vorlieben an',
        'Please select or drop a background file to import': 'Bitte wähle ein Motiv für die Rückseite aus oder lege es hier ab',
        'Please select or drop a logo file to import': 'Bitte wähle das Logo aus oder lege es hier ab',
        'No results found': 'Keine Ergebnisse gefunden',
        'title_homepage': 'Wunderpen Konfigurator',
        'title_products': 'Produkte - Wunderpen Konfigurator',
        'title_contact': 'Kontakt - Wunderpen Konfigurator',
        'title_sign_in': 'Einloggen - Wunderpen Konfigurator',
        'title_sign_up': 'Anmelden - Wunderpen Konfigurator',
        'title_sign_out': 'Abmelden - Wunderpen Konfigurator',
        'title_profile': 'Profil - Wunderpen Konfigurator',
        'title_forgot': 'Passwort vergessen - Wunderpen Konfigurator',
        'title_order_complete': 'Bestellung ausgeführt - Wunderpen Konfigurator',
        'title_card_order': 'Kartenbestellung - Wunderpen Konfigurator',
        'title_not_found': 'Seite nicht gefunden - Wunderpen Konfigurator',
        'The image quality is too low. Try zooming out or sending a bigger image.': 'Die Bildqualität ist zu niedrig. Passe das Bild anders an oder versuche ein Bild mit einer höheren Auflösung hochzuladen.',
        'The image quality is too low. Try sending a bigger image.': 'Die Bildqualität ist zu niedrig. Versuche ein Bild mit einer höheren Auflösung hochzuladen.',
        'Are you sure you change the order status?': 'Möchten Sie den Bestellstatus wirklich ändern?',
        'Landing page': 'Zielseite',
        'All products': 'Alle Produkte',
        //landing page:
        'hero_title': 'Handschriftliche Botschaften online gestalten und von Robotern geschrieben',
        'hero_subtitle': 'Werde kreativ und nutze unsere Technologie, um persönliche Botschaften zu designen und hinterlasse so einen bleibenden Eindruck bei deinen Liebsten',
        "Let's start": 'Jetzt starten',
        'blocks_1_title': 'Einfache Handhabung',
        'blocks_1_text': 'Bestimme einfach Format, Design und Menge deines Mailings in unserem übersichtlichen Bestellprozess und verfasse deinen persönlichen Text.',
        'blocks_2_title': 'Rundum persönlich',
        'blocks_2_text': 'Wähle aus unserer großen Vielfalt an personalisierbaren Designs und gestalte so dein echtes Mailing-Unikat.',
        'blocks_3_title': 'Sicher fehlerfrei',
        'blocks_3_text': 'Keine Sorge, vor dem Druck überprüfen wir dein gewähltes Layout und checken deinen Text auf Flüchtigkeitsfehler.',
        'blocks_4_title': 'Individuelle Lieferung',
        'blocks_4_text': 'Entscheide dich für eine unserer Versandoptionen und lass dein Mailing wahlweise direkt an den Empfänger oder zuerst an dich schicken.',
        'landing_font_title': 'Unsere Handschriften',
        'landing_font_subtitle': 'Wähle aus unserer vielseitigen Auswahl an Handschriften die passende aus. Eine Auswahl unserer favorisierten Handschriften findest du hier:',
        'landing_become_individual': 'Werde individuell',
        'landing_personalization_title': 'Personalisiere dein Mailing und wähle dein eigenes Logo und Design',
        'landing_personalization_subtitle': 'Du hast die Möglichkeit, deiner Kreativität freien Lauf zu lassen: Wähle aus drei verschieden Formaten das für dich passende aus und designe es nach deinen persönlichen Vorstellungen.',
        'video_title': 'Erklärungsvideo über uns',
        'landing_call_to_action_1': 'Jetzt hier bestellen',
        'landing_call_to_action_2': 'Zur neuen Produktserie',
        'PayPal': 'PayPal',
        'Already exists': 'Diese E-Mail ist bereits registriert',
        'waiting_time_singular': 'Werktag Lieferzeit',
        'waiting_time_plural': 'Werktage Lieferzeit',
        '(optional)': '(Optional)',
        'VAT ID': 'USt-IdNr.',
        'Name/Company': 'Name/Firma',
        'Custom salutation': 'Eigene Anrede',
        'Please enter masculine and feminine forms of the salutation.': 'Bitte geben Sie die männliche und weibliche Form der Anrede ein.',
        'Do not print address': 'Adresse nicht drucken',
        'Printable PDF': 'Druckbare PDF',
        'Price (netto)': 'Preis (zzgl. MwSt.)',
        'Export TXT': 'TXT exportieren',
        'Invalid': 'Ungültig',
        'in card': 'in Karte',
        'and': 'und',
        'validation errors': 'Fehler',
        'Go to card': 'Zur Karte',
        'Too short': 'Zu kurz',
        'Too long': 'Zu lang',
        'Invalid': 'Ungültig',
        'Invalid zip code for this country': 'Ungültiges Postleitzahlenformat für dieses Land',
        'You have to choose an option': 'Sie müssen eine der verfügbaren Optionen auswählen',
        'Unknown option selected': 'Unbekannte Option ausgewählt',
        'this is a required field': 'Dies ist ein Pflichtfeld',
        'First and last name combined can be maximum {{len}} characters long': 'Vor- und Nachname zusammen können maximal {{len}} Zeichen lang sein',
        'Too long (maximum {{len}} characters long)': 'Zu lang (maximal {{len}} Zeichen lang)',
        'Company': 'Firma',
        'Company (optional)': 'Firma (optional)',
        'Custom background': 'Eigene Hintergrund',
        'Custom inner background': 'Eigene Vorderseite Hintergrund',
        'Add custom inner background': 'Vorderseite hinzufügen',
        'Please select or drop an inner background file to import': 'Bitte wähle ein Motiv für die Vorderseite aus oder lege es hier ab',
        'Inner background': 'Vorderseite Hintergrund',
        'status_order_received': 'Bestellung eingegangen',
        'status_material_ordered': 'Material bestellt',
        'status_material_available': 'Material vorhanden',
        'status_in_production': 'In Produktion',
        'status_in_qa': 'In QA',
        'status_sent_out': 'Versendet',
        'Saving...': 'Speichern...',
        'Notes': 'Kommentarfeld',
        'Production status': 'Produktionsstatus',
        'Swell status': 'Swell Status',
        'A recovery e-mail has been sent. Please check your inbox.': 'Eine E-Mail zur Passwortwiederherstellung wurde versendet. Bitte überprüfe dein Postfach.',
        'Your password has been reset. You may now login.': 'Dein Passwort wurde zurückgesetzt. Du kannst dich nun einloggen.',
        'First Page': 'Erste Seite',
        'Second Page': 'Zweite Seite',
        'First page body': 'Text erste Seite',
        'Second page body': 'Text zweite Seite',
        'Heading on first page': 'Anrede erste Seite',
        'Heading on second page': 'Anrede zweite Seite',
        'Continue order': 'Bestellung fortsetzen', //TODO: check if the translation is correct
        'Cancel order': 'Bestellung abbrechen', //TODO: check if the translation is correct
        'Are you sure you want to start a new order? Your current order progress will be lost.': 'Möchten Sie wirklich eine neue Bestellung starten? Ihre aktuelle Bestellung wird dabei gelöscht.', //TODO: check if the translation is correct
        'Unknown': 'Unbekannt', //TODO: check if the translation is correct
        'Date created': 'Erstellt am', //TODO: check if the translation is correct
        'Last modified': 'Zuletzt geändert', //TODO: check if the translation is correct
        'Continue': 'Fortsetzen', //TODO: check if the translation is correct
        'Drafts': 'Entwürfe', //TODO: check if the translation is correct
        'Order drafts': 'Bestellentwürfe', //TODO: check if the translation is correct
        'Are you sure you want to continue from this order draft? Your current order progress will be lost.': 'Möchten Sie wirklich von diesem Bestellentwurf fortfahren? Ihre aktuelle Bestellung wird dabei gelöscht.', //TODO: check if the translation is correct
        'Save draft': 'Entwurf speichern', //TODO: check if the translation is correct
        'Draft has been saved.': 'Entwurf wurde gespeichert.', //TODO: check if the translation is correct
        'You need to be logged in to save drafts.': 'Sie müssen eingeloggt sein, um Entwürfe zu speichern.', //TODO: check if the translation is correct
        'There was an error while saving the draft.': 'Beim Speichern des Entwurfs ist ein Fehler aufgetreten.', //TODO: check if the translation is correct
        'Order date': 'Bestelldatum', //TODO: check if the translation is correct
        'Reorder': 'Erneut bestellen', //TODO: check if the translation is correct
        'Recipient lists': 'Empfängerlisten', //TODO: check if the translation is correct
        'Recipient list details': 'Details der Empfängerliste', //TODO: check if the translation is correct
        'New recipient list': 'Neue Empfängerliste', //TODO: check if the translation is correct
        'Create new recipient list': 'Neue Empfängerliste erstellen', //TODO: check if the translation is correct
        'Save': 'Speichern', //TODO: check if the translation is correct
        'Remove draft': 'Entwurf löschen', //TODO: check if the translation is correct
        'Are you sure you want to remove the draft?': 'Möchten Sie den Entwurf wirklich löschen?', //TODO: check if the translation is correct
        'This order draft is too old and cannot be loaded.': 'Dieser Bestellentwurf ist zu alt und kann nicht geladen werden.', //TODO: check if the translation is correct
        'Additional comments': 'Zusätzliche Kommentare', //TODO: check if the translation is correct
        'Comments': 'Kommentare', //TODO: check if the translation is correct
        'Client comments': 'Kundenkommentare', //TODO: check if the translation is correct
        'quantity_help': 'Bitte Wähle die Anzahl der Schriftstücke, die du in diesem Format erstellen möchtest.',
        'personalized_template_help': 'Bitte trage Deinen Text in das Feld "Inhalt" ein. Die "Anrede" und der "Name" werden zu einem späteren Zeitpunkt ergänzt.',
        'data_table_help': 'Bitte fülle alle rot markierten Felder korrekt aus. Das Ausfüllen der Firmenangabe ist optional. Überprüfe bitte, ob die Anrede mit deinem Empfänger übereinstimmt.',
        'data_upload_template_help': 'Bei Fragen zum Datenblatt wende dich bitte an hello@wunderpen.de',
        'pathSelect_fullCustomHelpText': 'Wähle diese Option, wenn du jedes deiner Schriftstücke mit einem individuellen Text gestalten möchtest.',
        'pathSelect_personalizedHelpText': 'Wähle diese Option, wenn du jedes deine r Schriftstücke mit dem selben Text gestalten möchtest. Hier kannst du Anrede und Adresse auf jedem deiner Schriftstück e individuell angeben.',
        'pathSelect_personalizedWithVariablesHelpText': 'Wähle diese Option, wenn du jedes deine r Schriftstücke mit dem gleichen Text gestalten möchtest. Neben Anrede und Adresse kannst du dabei auch einzelne Textbausteine auf jedem Deiner Schriftstück individuell gestalten.',
        'variant_help': 'Wenn du deine Empfänger mit "{{variant}}" ansprechen möchtest, wähle bitte diese Option aus.',
        'data_manual_help': 'Um Deine Empfänger manuell in eine Tabelle einzutragen, wähle bitte diese Option aus und klicke auf "weiter" um die Eingabe fortzusetzen.',
        'data_upload_help': 'Wenn du deine Kontakte über ein externes Datenblatt übermitteln möchtest, wähle bitte diese Option aus, und klicke auf "weiter", um das Datenblatt herunterzuladen.',
        'send_to_recipients_help': 'Bitte auswählen, wenn wir Deine Schriftstücke direkt zu den Empfängern verschicken dürfen.',
        'send_to_me_help': 'Bitte auswählen, wenn wir alle Deine Schriftstücke zu Dir verschicken dürfen.',
        'send_to_me_tile_help': 'Gib bitte die Adresse ein, an die wir alle Deine Schriftstücke versenden dürfen.',
        'payment_method_help': 'Wähle bitte eine Zahlungsmethode. Bei Fragen kontaktiere uns gerne unter hello@wunderpen.de',
        'edit_button_help': 'Über die Funktion "bearbeiten" kommst du zurück zur "Texteingabe".',
        'next_button_help': 'Klicke hier um Dir weiter Schriftstücke in der Ansicht anzeigen zu lassen.',
        'Minimum 150 DPI': 'Auflösung ≥ 150 DPI',
        'custom_innerbg_help': 'Möchtest du dein Logo auf einer anderen Position, lade es als Design der Vorderseite hoch.',
        'variables_help': 'Anleitung für Bausteine:\n1. Wähle das Feld "Variable hinzufügen" und benenne ihn\n2. Setze Deinen Baustein per Klick in den Text\n3. Du füllst Deinen Baustein im nächsten Schritt " Dateneingabe" aus.',
        'full_custom_text_help': 'Schreibe deinen Text in das Feld "Inhalt".',
        'full_custom_address_help': 'Bitte gebe die Adresse der Person an, die dein Brief erhalten wird. Diese wird automatisch auf den Umschlag übertragen und nicht als Briefkopf erstellt.',
        'do_not_print_address_help': 'Wähle diese Option, wenn du einen Blanko-Umschlag erhalten möchtest.',
        'Add your comments here': 'Anmerkungen zur Bestellung hier eintragen',
        'How does it work?': 'So funktioniert es ',
        'Choose your design': 'Los geht\'s! Wählen Sie Ihr Design!',
        'Companies that trust us': 'Beliebt bei großen Unternehmen',
        'Frequently asked questions': 'Häufig gestellte Fragen',
        'finalize_help': 'Die Funktion “Bearbeiten” führt dich zurück zur Texteingabe.',
        'Go to main page': 'Zurück zur Startseite',
        'WunderLink not found': 'WunderLink nicht gefunden',
        'The WunderLink you are looking for does not exist or has expired.': 'Der WunderLink, den Sie suchen, existiert nicht oder ist abgelaufen.',
        'Hello': 'Hallo',
        'Your WunderLink allows you to send up to {{quantity}} free cards.': 'Ihr WunderLink ermöglicht es Ihnen, bis zu {{quantity}} kostenlose Karten zu versenden.',
        'You can only add up to {{maxCards}} cards': 'Sie können nur bis zu {{maxCards}} Karten hinzufügen',
        'You can send {{count}} personalized cards for free!': 'Sie können bis zu {{count}} personalisierte Postkarten KOSTENLOS versenden.',
        "we're happy to see you here!": 'schön, dass Du hier bist!',
        'We offer you {{quantity}} free handmade cards.': 'Wir schenken Dir {{quantity}} kostenlose handgeschriebene Schriftstücke.',
        'Step {{num}}': '{{num}}. Schritt',
        'Choose a product below': 'Such Dir unten ein Produkt aus',
        'Customize your cards with our configurator': 'Erstelle die Handschriftlichen Produkte mit unserem Konfigurator',
        'Send cards to your recipients': 'Versende an Deine persönlichen Empfänger',
        'Thank you for your Wunderlink order': 'Vielen Dank für Deine Bestellung!',
        'We hope you enjoy your Wunderlink cards.': 'Wir wünschen Dir viel Freude an Deinen handschriftlichen Schriftstücken!',
        'Please take a look at our other products.': 'Falls Du Interesse an handschriftlichen Botschaften in höheren Auflagen hast, komme gerne direkt auf uns zu und nutze attraktive Mengenrabatte.',
        'Wunderlink contact': 'Kontakt aufnehmen',
        'Price per card': 'Stückpreis',
        'Card quantity': 'Auflage',
        'wunderlink_complete_table_summary': '(Berechnung auf Basis einer standard A6 Karte mit 275 Zeichen, exklusive Porto, exklusive Sonderanforderungen)',
        'Print Invoice': 'Rechnung drucken',
      },
    },
  },
  debug: true,
  // saveMissing: true,
  // missingKeyHandler: (lng, ns, key, fallbackValue, updateMissing, options) => {
  //   if (!missingTexts.includes(key)) {
  //     serverLog(ErrorLevels.WARN, "Missing translation", { language: lng, key });
  //     missingTexts.push(key);
  //   }
  // },

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
});

export default i18n;